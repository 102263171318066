var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "edit",
      class: {
        "allocation-modal": _vm.editingType === "strategicAllocation",
        "w-sm": _vm.showTickerIdSearch,
      },
    },
    [
      _vm.editingType !== null
        ? _c("BaseModal", {
            class: [
              { "show-modal": _vm.editingType !== null },
              _vm.editingType,
            ],
            scopedSlots: _vm._u(
              [
                {
                  key: "content",
                  fn: function () {
                    return [
                      _c("ticker-details", {
                        attrs: {
                          "node-name": _vm.editingNode.name,
                          benchmarkData: _vm.benchmarkData,
                          benchmarkCount: _vm.editingNode.benchmarks.length,
                          hasSecondaryBenchmark: _vm.hasSecondaryBenchmark,
                          rollForward: _vm.rollForwardData,
                          "ticker-type": _vm.editingType,
                          "ticker-details-data": _vm.tickersDetailsData,
                          "entity-name-id": _vm.editingNode.id,
                          "portfolio-id": _vm.portfolioId,
                          "show-manager-research": _vm.showManagerResearch,
                          "research-view": _vm.editingNode.researchView,
                          "selected-manager-fund": _vm.selectedManagerFund,
                          isEntityCustomInvestment:
                            _vm.isEntityCustomInvestment,
                        },
                        on: {
                          "discard-ticker-changes": _vm.discardTickerChanges,
                          saveRollForward:
                            _vm.saveRollForwardChangesWithTickerDetails,
                          saveBenchMarkChanges: _vm.saveBenchmarkChanges,
                          saveAllocationChanges: _vm.saveAllocationChanges,
                          "delete-benchmark": _vm.deleteBenchmark,
                          "delete-roll-forward": _vm.deleteRollForward,
                          "delete-strategic-allocation":
                            _vm.deleteStrategicAllocation,
                          updateShowTickerIdSearch:
                            _vm.updateShowTickerIdSearch,
                          hideManagerModal: _vm.hideManagerModal,
                          deleteAssociation: _vm.deleteAssociation,
                          saveManagerAssociation: _vm.saveManagerAssociation,
                        },
                      }),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              2938793202
            ),
          })
        : _vm._e(),
      _vm.isLoading
        ? _c("LoadingOverlay", { attrs: { "is-fetching-data": _vm.isLoading } })
        : _vm._e(),
      _vm._m(0),
      _c("BaseDivider"),
      _c(
        "section",
        { staticClass: "form" },
        [
          !_vm.isEntityPortfolio
            ? [
                _c("div", { staticClass: "first-name label" }, [
                  _vm._v(" " + _vm._s(_vm.nameLabel) + " "),
                ]),
                _c("div", [
                  _vm.editName
                    ? _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.editingNode.name,
                            expression: "editingNode.name",
                            modifiers: { trim: true },
                          },
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.nameError,
                            expression: "nameError",
                          },
                        ],
                        ref: "entityName",
                        class: { error: _vm.showError },
                        attrs: {
                          type: "text",
                          disabled: _vm.isEntityPortfolio,
                        },
                        domProps: { value: _vm.editingNode.name },
                        on: {
                          keyup: [
                            function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.handleNameChange.apply(null, arguments)
                            },
                            function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k($event.keyCode, "esc", 27, $event.key, [
                                  "Esc",
                                  "Escape",
                                ])
                              ) {
                                return null
                              }
                              return _vm.cancelName.apply(null, arguments)
                            },
                          ],
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.editingNode,
                              "name",
                              $event.target.value.trim()
                            )
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      })
                    : _c(
                        "button",
                        {
                          staticClass: "edit-btn",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              _vm.editName = true
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.editingNode.name) + " ")]
                      ),
                  _vm.editName && !_vm.isNewEntity
                    ? _c(
                        "span",
                        {
                          staticClass: "input-control confirm",
                          on: { click: _vm.handleNameChange },
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: ["fas", "check"] },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.editName && !_vm.isNewEntity
                    ? _c(
                        "span",
                        {
                          staticClass: "input-control cancel",
                          on: { click: _vm.cancelName },
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: ["fas", "times-circle"] },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
                _vm.showRollForward
                  ? _c("div", { staticClass: "label" }, [
                      _vm._v("Rollforward rule"),
                    ])
                  : _vm._e(),
                _vm.showRollForward
                  ? _c("div", [
                      _c(
                        "button",
                        {
                          staticClass: "edit-btn",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.showTickersModal("rollForward")
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.editRollForwardText) + " ")]
                      ),
                      _vm.canUseDynamicOverrides
                        ? _c(
                            "div",
                            { staticClass: "dynamic-overrides" },
                            [
                              _c(
                                "span",
                                { staticClass: "label-text" },
                                [
                                  _vm._v(" Use dynamic overrides? "),
                                  _c("font-awesome-icon", {
                                    directives: [
                                      {
                                        name: "tooltip",
                                        rawName: "v-tooltip",
                                        value: {
                                          content:
                                            "Calculates an estimated performance return between market value calibration points, based on change in value of the investment, allowing for inputted cashflows. Any manual overrides will supercede these overrides.",
                                          classes: "max-width-md",
                                        },
                                        expression:
                                          "{\n                                content: 'Calculates an estimated performance return between market value calibration points, based on change in value of the investment, allowing for inputted cashflows. Any manual overrides will supercede these overrides.',\n                                classes: 'max-width-md'\n                            }",
                                      },
                                    ],
                                    staticClass: "dynamic-overrides-info",
                                    attrs: { icon: ["fal", "info-circle"] },
                                  }),
                                  _vm._v(": "),
                                ],
                                1
                              ),
                              _c("BaseSwitch", {
                                attrs: {
                                  value: _vm.useDynamicOverridesForRollForward,
                                  disabled: false,
                                  tooltipText: _vm.dynamicOverridesTooltipText,
                                },
                                on: {
                                  input:
                                    _vm.toggleDynamicOverridesForRollForward,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ])
                  : _vm._e(),
              ]
            : _vm._e(),
          _vm.showBenchmarkRules
            ? _c("div", { staticClass: "label" }, [_vm._v("Benchmark rules")])
            : _vm._e(),
          _vm.showBenchmarkRules
            ? _c("BenchmarkRules", {
                attrs: {
                  benchmarkRules: _vm.benchmarkRules,
                  isBenchmarkRuleAPIPending: _vm.isBenchmarkRuleAPIPending,
                  canUseDynamicOverrides: _vm.canUseDynamicOverrides,
                },
                on: {
                  "open-benchmark-by-id": _vm.openBenchmarkById,
                  "delete-benchmark-by-id": _vm.deleteBenchmarkById,
                  "make-primary-by-id": _vm.makePrimaryById,
                  "toggle-dynamic-overrides-by-id":
                    _vm.toggleDynamicOverridesById,
                },
              })
            : _vm._e(),
          !_vm.isEntityPortfolio
            ? [
                !_vm.isNewEntity
                  ? _c("div", { staticClass: "first-name label" }, [
                      _vm._v("Strategic benchmark allocation"),
                    ])
                  : _vm._e(),
                !_vm.isNewEntity
                  ? _c("div", [
                      _c(
                        "button",
                        {
                          staticClass: "edit-btn",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.showTickersModal("strategicAllocation")
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.editAllocationText) + " ")]
                      ),
                    ])
                  : _vm._e(),
                _vm.showResearchView
                  ? _c("div", { staticClass: "label label--manager" }, [
                      _vm._v("Manager Research association"),
                    ])
                  : _vm._e(),
                _vm.showResearchView
                  ? _c(
                      "div",
                      [
                        _c(
                          "button",
                          {
                            staticClass: "edit-btn",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.showTickersModal(
                                  "entityManagerMapping"
                                )
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.selectedManagerFund
                                    ? _vm.selectedManagerFund.fundInfo.fundName
                                    : "not set"
                                ) +
                                " "
                            ),
                          ]
                        ),
                        _vm.showResearchText
                          ? [
                              _c("br"),
                              _c(
                                "span",
                                { staticClass: "research-view-text" },
                                [
                                  _vm._v(
                                    "Approval Status: " +
                                      _vm._s(
                                        _vm.selectedManagerFund.latestGrade &&
                                          _vm.selectedManagerFund.latestGrade
                                            .approvalStatus
                                          ? _vm.selectedManagerFund.latestGrade
                                              .approvalStatus
                                          : "not set"
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c("br"),
                              _c(
                                "span",
                                { staticClass: "research-view-text" },
                                [
                                  _vm._v(
                                    "Overall Grade: " +
                                      _vm._s(
                                        _vm.selectedManagerFund.latestGrade &&
                                          _vm.selectedManagerFund.latestGrade
                                            .overallGrade
                                          ? _vm.selectedManagerFund.latestGrade
                                              .overallGrade
                                          : "not set"
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c("br"),
                              _vm.showManagerResearchLink
                                ? _c(
                                    "a",
                                    {
                                      directives: [
                                        {
                                          name: "tooltip",
                                          rawName: "v-tooltip",
                                          value:
                                            "View " +
                                            _vm.selectedManagerFund.fundInfo
                                              .fundName +
                                            " research at " +
                                            _vm.managerResearchLink,
                                          expression:
                                            "`View ${selectedManagerFund.fundInfo.fundName} research at ${managerResearchLink}`",
                                        },
                                      ],
                                      staticClass: "manager-research-link",
                                      attrs: {
                                        href: _vm.managerResearchLink,
                                        target: "_blank",
                                      },
                                    },
                                    [
                                      _vm._v("View Manager Research "),
                                      _c("font-awesome-icon", {
                                        attrs: { icon: ["fas", "link"] },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          : _vm._e(),
                      ],
                      2
                    )
                  : _vm._e(),
              ]
            : _vm._e(),
          _vm.showExposures
            ? _c("div", { staticClass: "label" }, [_vm._v("Exposures")])
            : _vm._e(),
          _vm.showExposures
            ? _c("CustomInvestmentExposures", {
                attrs: {
                  exposures: _vm.selectedNodeExposureEntities,
                  isExposureAPIPending: _vm.isBenchmarkRuleAPIPending,
                },
                on: {
                  "open-exposure-by-id": _vm.openExposureById,
                  "delete-exposure-by-id": _vm.deleteExposureById,
                  "open-funding-notional": _vm.openFundingNotional,
                },
              })
            : _vm._e(),
        ],
        2
      ),
      _c("section", [
        _vm.isNewEntity
          ? _c("div", { staticClass: "confirmation flex" }, [
              _c(
                "div",
                {
                  staticClass: "btn btn--default discard",
                  on: {
                    click: function ($event) {
                      return _vm.$emit("close")
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "div",
                {
                  staticClass: "btn btn--default",
                  on: { click: _vm.saveNodeData },
                },
                [_vm._v("Create")]
              ),
            ])
          : _c("div", { staticClass: "confirmation flex" }, [
              _c(
                "div",
                {
                  staticClass: "btn btn--default discard",
                  on: {
                    click: function ($event) {
                      return _vm.$emit("close")
                    },
                  },
                },
                [_vm._v("Close")]
              ),
            ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "edit-heading" }, [
      _c("h1", [_vm._v("Create / edit instructions")]),
      _c("p", [_vm._v("Please fill out the fields below.")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }