var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "benchmark-rules" },
    [
      _vm.benchmarkRules.length
        ? _c("table", [
            _c("thead", [
              _c("tr", [
                _c("th", { staticClass: "text-left" }, [_vm._v("Label")]),
                _c("th", { staticClass: "text-left" }, [_vm._v("Name")]),
                _c("th", { staticClass: "text-left" }, [_vm._v("Details")]),
                _vm.benchmarkRules.length > 1
                  ? _c("th", [_vm._v("Set as primary benchmark?")])
                  : _vm._e(),
                _c("th", [_vm._v("Match investment return?")]),
                _c("th", [_vm._v("Actions")]),
              ]),
            ]),
            _c(
              "tbody",
              _vm._l(_vm.benchmarkRules, function (benchmarkRule) {
                return _c(
                  "tr",
                  {
                    key: benchmarkRule.benchmarkId,
                    class: { loading: _vm.isBenchmarkRuleAPIPending },
                  },
                  [
                    _c(
                      "td",
                      [
                        _c("BaseButton", {
                          staticClass: "text-nowrap p-0",
                          attrs: {
                            type: "button",
                            tooltipText: "Edit",
                            disabled:
                              benchmarkRule.benchmarkId === null ||
                              (benchmarkRule.useDynamicOverrides &&
                                _vm.canUseDynamicOverrides),
                            buttonClass: "edit-btn",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.openBenchmarkById(
                                benchmarkRule.benchmarkId
                              )
                            },
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function () {
                                  return [
                                    _vm._v(
                                      " " +
                                        _vm._s(benchmarkRule.benchmarkLabel) +
                                        " "
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                      ],
                      1
                    ),
                    _c("td", [_vm._v(_vm._s(benchmarkRule.benchmarkName))]),
                    _c(
                      "td",
                      [
                        benchmarkRule.benchmarkId === null
                          ? _c("p", [
                              _vm._v("Sum-weight of underlying benchmarks"),
                            ])
                          : benchmarkRule.useDynamicOverrides &&
                            _vm.canUseDynamicOverrides
                          ? _c("p", [
                              _vm._v(
                                "Matches final return of investment (before any benchmark overrides)."
                              ),
                            ])
                          : [
                              _vm._l(
                                benchmarkRule.benchmarkPieces.slice(0, 1),
                                function (benchmarkPiece, index) {
                                  return _c(
                                    "p",
                                    { key: benchmarkPiece.benchmarkPieceId },
                                    [
                                      _vm._l(benchmarkPiece, function (piece) {
                                        return _c(
                                          "span",
                                          {
                                            key:
                                              piece.tickerId +
                                              piece.valueType +
                                              piece.shouldConvertToBaseCurrency,
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.numberValueFormatter(
                                                    piece.weight
                                                  ) +
                                                    " " +
                                                    piece.tickerId
                                                ) +
                                                ", "
                                            ),
                                          ]
                                        )
                                      }),
                                      _c("span", [
                                        _vm._v(
                                          " from " +
                                            _vm._s(
                                              _vm.formatToDisplayDate(
                                                benchmarkPiece[index]
                                                  .effectiveDate
                                              )
                                            ) +
                                            "."
                                        ),
                                      ]),
                                    ],
                                    2
                                  )
                                }
                              ),
                              benchmarkRule.benchmarkPieces.length > 1
                                ? _c(
                                    "details",
                                    [
                                      _c("summary", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              benchmarkRule.benchmarkPieces
                                                .length - 1
                                            ) +
                                            " historical record" +
                                            _vm._s(
                                              benchmarkRule.benchmarkPieces
                                                .length > 2
                                                ? "s"
                                                : ""
                                            ) +
                                            " "
                                        ),
                                      ]),
                                      _vm._l(
                                        benchmarkRule.benchmarkPieces.slice(1),
                                        function (benchmarkPiece) {
                                          return _c(
                                            "p",
                                            {
                                              key: benchmarkPiece.benchmarkPieceId,
                                            },
                                            [
                                              _vm._l(
                                                benchmarkPiece,
                                                function (piece) {
                                                  return _c(
                                                    "span",
                                                    {
                                                      key:
                                                        piece.tickerId +
                                                        piece.valueType +
                                                        piece.shouldConvertToBaseCurrency,
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.numberValueFormatter(
                                                              piece.weight
                                                            ) +
                                                              " " +
                                                              piece.tickerId
                                                          ) +
                                                          ", "
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              _c("span", [
                                                _vm._v(
                                                  " from " +
                                                    _vm._s(
                                                      _vm.formatToDisplayDate(
                                                        benchmarkPiece[0]
                                                          .effectiveDate
                                                      )
                                                    ) +
                                                    "."
                                                ),
                                              ]),
                                            ],
                                            2
                                          )
                                        }
                                      ),
                                    ],
                                    2
                                  )
                                : _vm._e(),
                            ],
                      ],
                      2
                    ),
                    _vm.benchmarkRules.length > 1
                      ? _c("td", [
                          _c("div", { staticClass: "benchmark-column" }, [
                            !benchmarkRule.isPrimaryBenchmark
                              ? _c(
                                  "div",
                                  { staticClass: "primary-icon" },
                                  [
                                    _c("BaseButton", {
                                      attrs: {
                                        type: "button",
                                        tooltipText: "Set as primary benchmark",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.makePrimaryById(
                                            benchmarkRule.benchmarkId
                                          )
                                        },
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function () {
                                              return [
                                                _c("font-awesome-icon", {
                                                  staticClass: "action-icon",
                                                  attrs: {
                                                    icon: ["fal", "check"],
                                                  },
                                                }),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]),
                        ])
                      : _vm._e(),
                    _c("td", [
                      _vm.canUseDynamicOverrides
                        ? _c(
                            "div",
                            { staticClass: "benchmark-column" },
                            [
                              _c("BaseSwitch", {
                                attrs: {
                                  tooltipText:
                                    "" +
                                    (benchmarkRule.useDynamicOverrides
                                      ? "Revert to the benchmark rule"
                                      : "Match the final return of the investment"),
                                  disabled: false,
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.toggleDynamicOverridesById(
                                      benchmarkRule.benchmarkId
                                    )
                                  },
                                },
                                model: {
                                  value: benchmarkRule.useDynamicOverrides,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      benchmarkRule,
                                      "useDynamicOverrides",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "benchmarkRule.useDynamicOverrides",
                                },
                              }),
                            ],
                            1
                          )
                        : _c("div", { staticClass: "benchmark-column" }, [
                            _c("p", [_vm._v("n/a")]),
                          ]),
                    ]),
                    _c("td", [
                      _c(
                        "div",
                        { staticClass: "actions" },
                        [
                          _c("BaseButton", {
                            attrs: {
                              type: "button",
                              tooltipText: "Edit",
                              disabled:
                                benchmarkRule.benchmarkId === null ||
                                (benchmarkRule.useDynamicOverrides &&
                                  _vm.canUseDynamicOverrides),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.openBenchmarkById(
                                  benchmarkRule.benchmarkId
                                )
                              },
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function () {
                                    return [
                                      _c("font-awesome-icon", {
                                        staticClass: "action-icon",
                                        attrs: { icon: ["fal", "edit"] },
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                          _c("BaseButton", {
                            attrs: {
                              type: "button",
                              tooltipText: "Delete",
                              disabled:
                                benchmarkRule.benchmarkId === null ||
                                (benchmarkRule.isPrimaryBenchmark &&
                                  _vm.benchmarkCount > 1),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.showConfirmDeleteModal(
                                  benchmarkRule.benchmarkId,
                                  benchmarkRule.benchmarkName
                                )
                              },
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function () {
                                    return [
                                      _c("font-awesome-icon", {
                                        staticClass: "action-icon",
                                        attrs: { icon: ["fal", "trash-alt"] },
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                )
              }),
              0
            ),
          ])
        : _vm._e(),
      _vm.showAddBenchmarkButton
        ? _c("BaseButton", {
            attrs: { buttonClass: "edit-btn" },
            on: {
              click: function ($event) {
                return _vm.openBenchmarkById(null)
              },
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function () {
                    return [
                      _c("font-awesome-icon", {
                        staticClass: "action-icon",
                        attrs: { icon: ["fal", "plus"] },
                      }),
                      _vm._v(" add new benchmark "),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              1097128918
            ),
          })
        : _vm._e(),
      _vm.deleteModal.active
        ? _c("BaseModal", {
            class: { "show-modal": _vm.deleteModal.active },
            scopedSlots: _vm._u(
              [
                {
                  key: "content",
                  fn: function () {
                    return [
                      _c(
                        "ConfirmDeletion",
                        {
                          attrs: {
                            "delete-keyword": _vm.deleteModal.deleteKeyword,
                          },
                          on: {
                            discardDeleteModal: _vm.discardDeleteModal,
                            confirmDelete: _vm.confirmDelete,
                          },
                        },
                        [
                          _c("p", [
                            _vm._v(
                              "You're about to delete " +
                                _vm._s(_vm.deleteModal.deleteKeyword) +
                                ", this will also delete all associated performance overrides."
                            ),
                          ]),
                        ]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              4090406131
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }